
        <section id="header" data-anchor="top" class="section fp-auto-height-responsive">
                <div class="container">
                    <div class="h1-container">
                        <h1><span class="pure-bar">\</span> références</h1>
                    </div>
            
            <a (click)="scrollAll()" class="scroll-bottom">
                <svg id="picto-cta-scroll" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                    <g id="fleche">
                        <path id="tete_de_fleche" data-name="tete de fleche" d="M45.67,38.09,33.83,49.93,45.67,61.77" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
                        <path id="corps_de_fleche" data-name="corps de fleche" d="M66.6,49.93H33.75" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
                    </g>
                    <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                    <circle id="cercle-blanc" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000000" stroke-width="2.09"/>
                </svg>
            </a>
        </div>
        </section>

        <section id="reference-section" class="section">

            <div class="container">
                <div class="filter-container">
                    <div id="menu-filter" class="filters">
                        
                        <div class="reference-filter active"
                        id="filter-id-all"
                        (click)="filterThis('all')">
                            <p>Tout voir <span>\</span></p>
                        </div>
                        <div *ngFor="let reference of references; let index = index" 
                        class="reference-filter" 
                        id="filter-id-{{reference.slug}}"
                        (click)="filterThis(reference.slug)">
                            <span class="ico-plus">
                                <span class="trait"></span>
                                <span class="trait"></span>
                            </span>
                            <p *ngIf="reference.slug === 'developpement-durable-et-ecologie'; else elseTemplate">
                                Développement <br>durable & Écologique
                             </p>
                             <ng-template #elseTemplate>
                                <p>{{reference.category}} <span>\</span></p>
                             </ng-template>
                        </div>
                    </div>
                </div>
                <div class="all-references">
                    <ng-container *ngFor="let reference of allReferences" >
                        <div *ngFor="let projet of reference.projet" 
                        class="single-ref {{reference.slug}}">
                            <div class="content">
                                <img [routerLink]="['/references', reference.slug, projet.slug]" src="../../../../assets/images/references/{{reference.slug}}/{{projet.slug}}/{{projet.slug}}-slider.jpg" class="">
                                <div class="infos-ref">
                                    <h3><span class="barre">\ </span><span [innerHTML]="projet.shortName"></span></h3>
                                    
                                    <div class="bottom">
                                        <p class="lineSkill">
                                            {{projet.lineSkill}}
                                        </p>

                                        <div [routerLink]="['/references', reference.slug, projet.slug]" class="cta-projet">
                                            <div class="txt">
                                                <span>Découvrir</span>
                                                <div class="trait">
                                                    <div class="trait-anim"></div>
                                                </div>
                                            </div>
                                            <div class="picto">
                                                <svg id="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                                                    <g id="fleche">
                                                        <path id="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#9c974f" stroke-width="6.56"/>
                                                        <path id="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#9c974f" stroke-width="6.56"/>
                                                    </g>
                                                    <circle cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9c974f" stroke-width="2.09"/>
                                                    <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#fff" stroke-width="2.09"/>
                                                </svg>                         
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </section>

        <section id="footer-agence" class="section">
        <section id="decouvrir">
            <div class="container">
                <div class="col">
                    <h2>Découvrir aussi</h2>
                </div>
                <div class="col">
                    <app-main-bt [routerLink]="['/l-agence']" class="link-bt">l'agence</app-main-bt>
                    <app-main-bt [routerLink]="['/contact']" class="link-bt">contact</app-main-bt>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
        </section>