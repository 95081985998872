<app-homepage-loader *ngIf="this.globals.showLoader" id="homepage-loader-container"></app-homepage-loader>

<section id="header">
<div class="container pointer-none">
    <div class="bt-play"  (click)="showVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" width="221" height="219" viewBox="0 0 221 219">
            <g id="Groupe_6849" data-name="Groupe 6849" transform="translate(-849 -415)">
            <g id="Groupe_6848" data-name="Groupe 6848" transform="translate(849.414 415.035)">
            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-0.414 -0.036)" fill="none" stroke="#fff" stroke-width="3">
            <ellipse cx="110.5" cy="109.5" rx="110.5" ry="109.5" stroke="none"/>
            <ellipse cx="110.5" cy="109.5" rx="109" ry="108" fill="none"/>
            </g>
            </g>
            <path id="Polygone_1" data-name="Polygone 1" d="M24.5,0,49,43H0Z" transform="translate(992 500) rotate(90)" fill="#fff"/>
            </g>
        </svg>
    </div>
    <div id="bt-mute" (click)="muteVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" width="27.122" height="22.377" viewBox="0 0 27.122 22.377">
            <g id="Groupe_6850" data-name="Groupe 6850" transform="translate(0 0)">
              <path id="Tracé_12840" data-name="Tracé 12840" d="M6303.989,2145.053a.869.869,0,0,0-.964.208l-3.986,4.478a1.582,1.582,0,0,1-1.179.528h-5.41a1.184,1.184,0,0,0-1.183,1.182v7.354a1.184,1.184,0,0,0,1.183,1.182h5.41a1.58,1.58,0,0,1,1.179.528l3.986,4.478a.884.884,0,0,0,1.52-.613v-18.5A.87.87,0,0,0,6303.989,2145.053Z" transform="translate(-6291.267 -2143.937)" fill="#707070"/>
              <path id="Tracé_12841" data-name="Tracé 12841" d="M6452.909,2207.489a1.111,1.111,0,0,0-1.521.39,1.113,1.113,0,0,0,.391,1.521,2.477,2.477,0,0,1,0,4.267,1.112,1.112,0,0,0-.391,1.521,1.11,1.11,0,0,0,1.521.39,4.7,4.7,0,0,0,0-8.088Z" transform="translate(-6436 -2200.345)" fill="#707070"/>
              <path id="Tracé_12842" data-name="Tracé 12842" d="M6472.612,2167.738a1.1,1.1,0,0,0-.562-.155,1.127,1.127,0,0,0-.278.035,1.11,1.11,0,0,0-.289,2.03,6.878,6.878,0,0,1,0,11.839,1.111,1.111,0,0,0-.391,1.52,1.109,1.109,0,0,0,1.521.391,9.1,9.1,0,0,0,0-15.66Z" transform="translate(-6453.826 -2164.38)" fill="#707070"/>
              <path id="Tracé_12843" data-name="Tracé 12843" d="M6494.058,2134.1a1.106,1.106,0,0,0-.563-.155,1.121,1.121,0,0,0-.278.035,1.11,1.11,0,0,0-.289,2.031,10.6,10.6,0,0,1,0,18.245,1.111,1.111,0,0,0-.391,1.52,1.116,1.116,0,0,0,.957.545,1.1,1.1,0,0,0,.563-.155,12.819,12.819,0,0,0,0-22.067Z" transform="translate(-6473.228 -2133.945)" fill="#707070"/>
            </g>
          </svg>
    </div>
    <div id="bt-unmute" (click)="unMuteVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.81" height="22.479" viewBox="0 0 32.81 22.479">
            <g id="Groupe_6851" data-name="Groupe 6851" transform="translate(0 -0.002)">
              <path id="Tracé_12844" data-name="Tracé 12844" d="M7406.959,2201.686v7.354a1.183,1.183,0,0,0,1.182,1.182h5.411a1.578,1.578,0,0,1,1.178.528l3.986,4.478a.883.883,0,0,0,1.52-.613v-6l-12.228-8.1A1.187,1.187,0,0,0,7406.959,2201.686Z" transform="translate(-7404.115 -2194.122)" fill="#707070"/>
              <path id="Tracé_12845" data-name="Tracé 12845" d="M7503.532,2145.053a.87.87,0,0,0-.964.208l-2.931,3.292,4.45,2.947v-5.626A.869.869,0,0,0,7503.532,2145.053Z" transform="translate(-7487.967 -2143.885)" fill="#707070"/>
              <path id="Tracé_12846" data-name="Tracé 12846" d="M7567.473,2209.4a2.5,2.5,0,0,1,.724.645l2.7,1.791c.006-.1.01-.2.01-.3a4.657,4.657,0,0,0-2.307-4.044,1.11,1.11,0,1,0-1.13,1.91Z" transform="translate(-7548.848 -2200.293)" fill="#707070"/>
              <path id="Tracé_12847" data-name="Tracé 12847" d="M7587.174,2169.647a6.816,6.816,0,0,1,3.377,5.919,6.912,6.912,0,0,1-.146,1.415l1.957,1.3a9.151,9.151,0,0,0,.409-2.711,9.017,9.017,0,0,0-4.466-7.83,1.11,1.11,0,0,0-1.131,1.911Z" transform="translate(-7566.673 -2164.326)" fill="#707070"/>
              <path id="Tracé_12848" data-name="Tracé 12848" d="M7587.008,2314.84a1.11,1.11,0,0,0-.224,1.4,1.109,1.109,0,0,0,1.521.391c.236-.14.473-.3.705-.465Z" transform="translate(-7566.673 -2297.56)" fill="#707070"/>
              <path id="Tracé_12849" data-name="Tracé 12849" d="M7608.11,2340.037a1.1,1.1,0,0,0,.119.841,1.118,1.118,0,0,0,.957.545,1.105,1.105,0,0,0,.563-.155,12.973,12.973,0,0,0,2-1.459l-1.93-1.278a10.757,10.757,0,0,1-1.2.828A1.1,1.1,0,0,0,7608.11,2340.037Z" transform="translate(-7586.077 -2318.994)" fill="#707070"/>
              <path id="Tracé_12850" data-name="Tracé 12850" d="M7608.62,2136.011a10.506,10.506,0,0,1,5.2,9.123,10.649,10.649,0,0,1-.639,3.65l1.894,1.254a12.848,12.848,0,0,0-5.328-15.938,1.11,1.11,0,0,0-1.13,1.911Z" transform="translate(-7586.077 -2133.893)" fill="#707070"/>
              <path id="Tracé_12851" data-name="Tracé 12851" d="M7409.415,2153.857l-30.592-20.258a1.1,1.1,0,0,0-.611-.185h0a1.11,1.11,0,0,0-.614,2.035l30.591,20.259a1.11,1.11,0,0,0,1.227-1.851Z" transform="translate(-7377.101 -2133.413)" fill="#707070"/>
            </g>
          </svg>
    </div>
    <video id="video-pure" width="100%" height="100%"  (click)="showVideo()">        
        <source src="../../../assets/video/pure.mp4" type="video/mp4">
    
        Sorry, your browser doesn't support embedded videos.
    </video>

    <div class="bg-txt-v2">
        <span class="item-4">NO STANDARDS</span>
        <span class="item-3">NO STANDARDS</span>
        <span class="item-2">NO STANDARDS</span>
        <span class="item-1">NO STANDARDS</span>
    </div><!--
    <div class="bg-txt"></div>
    
    <div class="cadre">
        <div class="top"></div>
        <div class="bot"></div>
        <div class="left"></div>
        <div class="right"></div>
    </div>-->
    <div class="logo">
        <h1 class="ignore">
            <div class="top-hide"></div>
            <div class="bot-hide"></div>
            <div id="logo-p">
                <svg  xmlns="http://www.w3.org/2000/svg" width="89.2" height="114.794" viewBox="0 0 89.2 114.794">
                    <path id="Tracé_12839" data-name="Tracé 12839" d="M35.7,30.774l65.5,114.795h23.7L59.4,30.774Z" transform="translate(-35.697 -30.774)" fill="#fff"/>
                    </svg>
                </div>
                <div id="logo-ure">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="415.66" height="116.929" viewBox="0 0 415.66 116.929">
                        <title>PURE</title>
                        <g id="Groupe_4191" data-name="Groupe 4191" transform="translate(-52.169)">
                            <g id="Groupe_4192" data-name="Groupe 4192" transform="translate(0 0)">
                            <g id="Groupe_4191-2" data-name="Groupe 4191">
                                <path id="Tracé_9719" data-name="Tracé 9719" d="M149.874,99.477c0,18.537-6.888,27.555-22.14,27.555-15.415,0-22.14-9.015-22.14-27.555v-68.7H84.442V99.811c0,31.653,15.408,47.89,42.963,47.89,27.386,0,43.623-16.238,43.623-47.89V30.773H149.874Z" transform="translate(74.079 -30.773)" fill="#fff"/>
                                <path id="Tracé_9720" data-name="Tracé 9720" d="M177.964,127.122V96.7h39.358V78.248H177.964V49.269h52.149v-18.5h-73.3V145.567h73.962V127.122Z" transform="translate(237.057 -30.773)" fill="#fff"/>
                                <path id="Tracé_9722" data-name="Tracé 9722" d="M99.893,30.788H51.739L62.321,49.234H97.515a18.223,18.223,0,0,1,.01,36.446H83.242l10.586,18.449h6.286c20.069,0,35.786-16.111,35.786-36.674S120.085,30.788,99.893,30.788Z" transform="translate(0.43 -30.739)" fill="#fff"/>
                                <path id="Tracé_9723" data-name="Tracé 9723" d="M203.229,67.455c0-20.563-15.815-36.667-36.007-36.667H143.368c14.7,0-21.155,0-21.155,0V145.534h21.155V104.129h13.825l20.618,41.405h25.418l-22.989-43.78C193.8,96.578,203.229,83.368,203.229,67.455ZM164.854,85.68H143.368V49.234h21.477a18.223,18.223,0,0,1,.01,36.446Z" transform="translate(159.143 -30.739)" fill="#fff"/>
                            </g>
                            </g>
                        </g>
                        </svg>
                    </div>
        </h1>
        <span class="signature">NO STANDARDS</span>
    </div>
</div>
<a (click)="scrollAboutUs()" class="scroll-bottom">
    <svg id="picto-cta-scroll" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
        <g id="fleche">
            <path id="tete_de_fleche" data-name="tete de fleche" d="M45.67,38.09,33.83,49.93,45.67,61.77" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
            <path id="corps_de_fleche" data-name="corps de fleche" d="M66.6,49.93H33.75" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
        </g>
        <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#2b2b2b" stroke-width="2.09"/>
        <circle id="cercle-blanc" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#fff" stroke-width="2.09"/>
    </svg>
</a>
</section>
<section id="about-us">
<div class="container">
    <div class="col col-left">
        <p>
            <span class="pure-bar">\</span> Il paraît que les règles sont faites pour être enfreintes. Chez Pure, nous en sommes convaincus.
        </p>
    </div>
    <div class="col col-right">
        <p>Depuis sa création en 2013, Pure propose à ses clients une approche design de la communication : utile, esthétique et inattendue. Grâce à une intuition créative, toujours impulsée par une analyse objective, nous amenons nos clients hors de cette fameuse zone de complaisance. Nous leur offrons une expérience fluide et prévenante, et les moyens de tracer leur propre route, d’inventer leurs propres codes de communication. 
            </p>
            <app-main-bt [routerLink]="['/l-agence']">+ sur Pure</app-main-bt>
    </div>
</div>
</section>

<section id="bandeau-pure">
<div class="container">
    <span class="outline">
        Stratégie de marque
    </span>
    <span class="bold">
        Création
    </span>
    <span class="outline">
        Branding
    </span>
    <span class="bold">
        UX / UI
    </span>
    <span class="outline">
        STORYTELLING
    </span>
    <span class="bold">
        Stratégie de marque
    </span>
    <span class="outline">
        Création
    </span>
    <span class="bold">
        Branding
    </span>
    <span class="outline">
        UX / UI
    </span>
    <span class="bold">
        STORYTELLING
    </span>
    <span class="outline">
        Stratégie de marque
    </span>
    <span class="bold">
        Création
    </span>
    <span class="outline">
        Branding
    </span>
    <span class="bold">
        UX / UI
    </span>
    <span class="outline">
        STORYTELLING
    </span>
    <span class="bold">
        Stratégie de marque
    </span>
    <span class="outline">
        Création
    </span>
    <span class="bold">
        Branding
    </span>
    <span class="outline">
        UX / UI
    </span>
    <span class="bold">
        STORYTELLING
    </span>
</div>
</section>

<section id="projets">
<div class="container">
    <div class="row row-1">
        <div class="col col-left">
            <div class="projet-1 projet">
                <div class="image-container">
                    <img [routerLink]="['/references', 'services', 'culo']" src="../../../assets/images/homepage/Culo.jpg" alt="Pure - projet Culo">
                    <span class="number">1</span>
                </div>
                <div class="description">
                    <h2>\ Culo<br><span>Surcyclé<br>en France</span></h2>
                    <p>
                        Audit – Analyse data – Ux/Ui Design – Intégration – Développement
                    </p>
                    <app-alt-bt [routerLink]="['/references', 'services', 'culo']">découvrir</app-alt-bt>
                </div>
            </div>
        </div>
        <div class="col col-right">
            <div class="projet-2 projet">
                <div class="image-container">
                    <img [routerLink]="['/references', 'food', 'momes']" src="../../../assets/images/homepage/Momes.jpg" alt="Pure - projet Momes">
                    <span class="number">2</span>
                </div>
                <div class="description">
                    <div class="left">
                        <h2>\ Mômes,<br><span>Bande de<br>Gourmets</span></h2>
                        <p>
                            Planning Stratégique – Naming – Signature de marque – Logotype et identité visuelle – Brand content – Ux/Ui Design – Intégration – Développement – Marketing d’influence
                        </p>
                    </div>
                    <div class="right">
                        <app-alt-bt [routerLink]="['/references', 'food', 'momes']">découvrir</app-alt-bt>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-mid">
        <div class="col col-left">
            <div class="projet-3 projet">
                <div class="image-container">
                    <img [routerLink]="['/references', 'centre-commerciaux', 'les-boutiques-saint-georges']" src="../../../assets/images/homepage/BSG.jpg" alt="Pure - projet Les Boutiques Saint Georges">
                    <span class="number">3</span>
                </div>
                <div class="description">
                    <h2>\ Les Boutiques<br><span>Saint Georges</span></h2>
                    <div class="gc-infos">
                        <p>
                            Étude de marché – Planning Stratégique – Audit et Analyse Data – Brand Content – Conception/Rédaction – Production événementielle – Ux/Ui Design – Social Media Management – Marketing d’influence – Création et gestion des campagnes emailings - Édition
                        </p>
                        <app-alt-bt [routerLink]="['/references', 'centre-commerciaux', 'les-boutiques-saint-georges']">découvrir</app-alt-bt>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col col-left">
            <div class="projet-5 projet">
                <div class="image-container">
                    <img [routerLink]="['/references', 'services', 'solum']" src="../../../assets/images/homepage/Solum.jpg" alt="Pure - projet Solum">
                    <span class="number">5</span>
                </div>
                <div class="description">
                    <h2>\ Solum<br><span>Light is coming</span></h2>
                    <div class="gc-infos">
                        <p>
                            Étude de marché et data – Planning stratégique – Positionnement – Plateforme de de marque – Logotype & identité visuelle – Naming & signature de marque - Plateforme de discours – UX/UI – Développement
                        </p>
                        <app-alt-bt [routerLink]="['/references', 'services', 'solum']">découvrir</app-alt-bt>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-right">
            <div class="projet-4 projet">
                <div class="image-container">
                    <img [routerLink]="['/references', 'institution-et-territoire', 'vinci-autoroute']" src="../../../assets/images/homepage/Vinci.jpg" alt="Pure - projet Vinci">
                    <span class="number">4</span>
                </div>
                <div class="description">
                    <div class="left">
                        <h2>\ Vinci<br><span>Autoroutes</span></h2>
                        <p>
                            Étude de marché – Planning Stratégique – Audit et Analyse Data – Brand Content – Conception/Rédaction – Production événementielle – Édition
                        </p>
                    </div>
                    <div class="right">
                        <app-alt-bt [routerLink]="['/references', 'institution-et-territoire', 'vinci-autoroute']">découvrir</app-alt-bt>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="projet-footer">
        <app-main-bt [routerLink]="['/references']">+ de références</app-main-bt>
    </div>
</div>
</section>

<section id="decouvrir-home">
<div class="header-decouvrir">
    <h2>Découvrir aussi</h2>
    <div class="link">
        <app-main-bt [routerLink]="['/l-agence']" class="link-bt">l'agence</app-main-bt>
        <app-main-bt [routerLink]="['/l-agence']" fragment="metiers" class="link-bt">métiers</app-main-bt>
        <app-main-bt [routerLink]="['/l-agence']" fragment="equipe" class="link-bt">l'équipe</app-main-bt>
        <app-main-bt [routerLink]="['/le-feed']" class="link-bt">le feed</app-main-bt>
        <app-main-bt [routerLink]="['/contact']" class="link-bt">contact</app-main-bt>
    </div>
</div>
<div class="image-container">
    <div class="background-txt">
        <div class="text-container">
            <span class="item-5">NO STANDARDS</span>
            <span class="item-4">NO STANDARDS</span>
            <span class="item-3">NO STANDARDS</span>
            <span class="item-2">NO STANDARDS</span>
            <span class="item-1">NO STANDARDS</span>
        </div>
    </div>
</div>
</section>
<app-footer></app-footer>