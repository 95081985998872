import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ContactService } from 'src/app/contact.service';
import { SmoothScroll } from 'src/app/smoothscroll';
import { gsap, ScrollTrigger } from 'gsap/all';
import { Globals } from 'src/app/global';

@Component({
  selector: 'app-candidature',
  templateUrl: './candidature.component.html',
  styleUrls: ['./candidature.component.scss']
})
export class CandidatureComponent implements OnInit, AfterViewInit {
  FormData: FormGroup;
  

  //key : 6LewBzYaAAAAADERtNuET2hFF0LedBrtEB8pAVfQ
  //secret : 6LewBzYaAAAAAA_aXLlXnMUequaBzLhK4kb3n6pk
  //key : 6LemCDYaAAAAAGgC-PLBgTK-y2U-rZXDncFpvcB9
  //secret : 6LemCDYaAAAAABGIo7vroyBf1FkzlNkQsHgyUa2F

  constructor(private builder: FormBuilder,
    private contact: ContactService,
    private _smoothScroll: SmoothScroll,
    public globals: Globals) { }

  ngOnInit(): void {
    this._smoothScroll.destroyScrollBar();
    this.globals.globalScrollbar = this._smoothScroll.setSmoothScrolling();
    this.globals.pageType = "scroll";

    this.FormData = this.builder.group({
      Nom: new FormControl('', [Validators.required]),
      Documents: [''],
      Prenom: new FormControl('', [Validators.required]),
      Telephone: new FormControl('', [Validators.required]),
      Email : new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Message: new FormControl('', [Validators.required]),
      _subject: new FormControl('Nouveau message'),
      _after: new FormControl(''),
      _confirmation: new FormControl('Votre message à bien été envoyé !'),
      recaptchaReactive: new FormControl('', [Validators.required]),
      });
        
      gsap.fromTo('#scrolltop', 
      {
        opacity: 0,
      }
      ,
      { 
        opacity: 1,
        scrollTrigger: {
          trigger: "#header",
          start: "bottom top",
          toggleActions: "play none reverse reset"
        }
      });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.FormData.get('Documents').setValue(file);
    }
  }

  onSubmit(FormDataForm){
    document.getElementById('formContact').classList.add('processing');
    delete FormDataForm.recaptchaReactive;
    
    const formDataFile = new FormData();
    formDataFile.append('file', this.FormData.get('Documents').value);
    formDataFile.append('Nom', FormDataForm.Nom);
    formDataFile.append('Prenom', FormDataForm.Prenom);
    formDataFile.append('Telephone', FormDataForm.Telephone);
    formDataFile.append('Email', FormDataForm.Email);
    formDataFile.append('Message', FormDataForm.Message);
    formDataFile.append('_subject', 'Nouvelle candidature');
    formDataFile.append('_confirmation', 'Votre message à bien été envoyé !');
    

    this.contact.PostMessageRecrutement(formDataFile)
      .subscribe(response => {
        location.href = 'https://mailthis.to/confirm'
        console.log(response)
      }, error => {
        console.warn(error.responseText)
      console.log({ error })
      }) 
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
  }

  ngAfterViewInit(): void {
    var thiObj = this;
    setTimeout(function(){
      thiObj.startAnimations();
    }, 500);
  }

  startAnimations(){
    var section = document.querySelector("#separateur-reunion");
    let bg = <HTMLElement>section.querySelector(".bg");
    bg.style.backgroundPosition = `50% ${-innerHeight / 2}px`;

    gsap.to(bg, {
      backgroundPosition: `50% ${innerHeight / 2}px`,
      ease: "none",
      scrollTrigger: {
        trigger: section,
        scrub: true
      }
    });
  }

}
