<div id="fullpage-container">
<div fullpage id="fullpage" [options]="config" (ref)="getRef($event)">
    <section id="header-section" data-anchor="top" class="section fp-auto-height-responsive">
        <div class="container">
            <div class="h1-container">
                <h1><span class="pure-bar">\</span> l'agence</h1>
            </div>

            <a (click)="scrollApproche()" class="scroll-bottom">
                <svg id="picto-cta-scroll" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                    <g id="fleche">
                        <path id="tete_de_fleche" data-name="tete de fleche" d="M45.67,38.09,33.83,49.93,45.67,61.77" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
                        <path id="corps_de_fleche" data-name="corps de fleche" d="M66.6,49.93H33.75" transform="translate(-2.1 -1.85)" fill="none" stroke="#9C974F" stroke-width="6.56"/>
                    </g>
                    <circle id="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                    <circle id="cercle-blanc" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000000" stroke-width="2.09"/>
                </svg>
            </a>
        </div>
    </section>

    <section id="notre-approche-section" data-anchor="notre-approche" class="section fp-auto-height-responsive">
        <div class="container">
            <div class="col col-left">
                <h2>\ NOTRE<br><span>APPROCHE</span></h2>
                <p> 
                    La course à l’attention est une réalité pour toutes les marques. Nous pensons que pour être entendu, il ne suffit pas de parler fort. Pour exister, pour rassembler, nous devons nous libérer des standards. Nous devons nous fier à notre intuition, nous autoriser l’imprévu créatif. Chez Pure, nous accompagnons nos clients là où ils n’auraient pas osé. Avec écoute, prévenance et pédagogie, nous leur donnons les moyens d’inventer leurs propres codes.
                    <br><br>
                    Cela dit, il serait malvenu de prôner cette philosophie, la nôtre, sans l’incarner. Chez Pure, nous considérons les conventions comme une entrave à la créativité. Ou peut-être cette fâcheuse tendance à l’anticonformisme est-elle le moteur de notre agence ?
                    <br><br>
                    Bref, Pure, mais certainement pas tout blanc.
                </p>
                <div class="fix-flex"></div>
            </div>
            <div class="col col-right">
                <img src="../../../assets/images/l-agence/notre-approche-illu.png" alt="NO STANDARDS">
            </div>
        </div>
    </section>

    <section id="metiers-section" data-anchor="metiers" class="section fp-auto-height-responsive">
        <div class="container">
            <div class="nav-page nav-page-mobile"><span class="current">1</span> / 7</div>
            <div class="top-left">
                <h2>\ Métiers</h2>
                <div class="nav-page"><span class="current">1</span> / 7</div>
            </div>
            <div class="nav-slider">
                <span>Découvrir</span>
                <div class="slideGoLeft" (click)="slickPrevSlide('carousel-metier')">
                    <div class="picto">
                        <svg class="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                            <g class="fleche">
                                <path class="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#000" stroke-width="6.56"/>
                                <path class="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#000" stroke-width="6.56"/>
                            </g>
                            <circle class="cercle-base" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000" stroke-width="2.09"/>
                            <circle class="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                        </svg>                         
                    </div>
                </div>
                <div class="slideGoRight" (click)="slickNextSlide('carousel-metier')">
                    <div class="picto">
                        <svg class="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                            <g class="fleche">
                                <path class="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#000" stroke-width="6.56"/>
                                <path class="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#000" stroke-width="6.56"/>
                            </g>
                            <circle class="cercle-base" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#000" stroke-width="2.09"/>
                            <circle class="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                        </svg>                         
                    </div>
                </div>
            </div>
            <div class="metier-slider">
                <ngx-slick-carousel class="carousel carousel-metier" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfigMetier" 
                        (init)="slickInitMetier($event)"
                        (afterChange)="afterChangeMetier($event)"
                        (beforeChange)="beforeChangeMetier($event)">

                        <div ngxSlickItem class="single-slide metier-1" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-1.jpg);">
                            <div class="container-slider">
                                <h3 class="exclusion">Conseil & Stratégie<br>de marque</h3>
                                <div class="skill-list">
                                    <span>audit</span>
                                    <span>conseil</span>
                                    <span>planning<br>stratégique</span>
                                    <span>plateforme de<br>marque</span>
                                    <span>territoire<br>d'expression</span>
                                    <span>brand book</span>
                                    <span>creative<br>guidelines</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-2" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-2.jpg);">
                            <div class="container-slider">
                                <h3 class="">Brand<br>Content</h3>
                                <div class="skill-list">
                                    <span>Stratégie de<br>contenu</span>
                                    <span>Direction<br>artistique</span>
                                    <span>Stratégie<br>d'activation</span>
                                    <span>Storytelling</span>
                                    <span>Production photo</span>
                                    <span>Production film</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-3" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-3.jpg);">
                            <div class="container-slider">
                                <h3 class="">Stratégie d'image<br>& publicité</h3>
                                <div class="skill-list">
                                    <span>Direction de<br>création</span>
                                    <span>Création de<br>concepts créatifs</span>
                                    <span>Campagne<br>publicitaire - print,<br>TV, online</span>
                                    <span>identité visuelle</span>
                                    <span>Design graphique</span>
                                    <span>Campagne<br>d'activation</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-4" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-4.jpg);">
                            <div class="container-slider">
                                <h3 class="">Édition</h3>
                                <div class="skill-list">
                                    <span>Conception et<br>rédaction</span>
                                    <span>Iconographie</span>
                                    <span>Supports<br>de marque</span>
                                    <span>Production</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-5" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-5.jpg);">
                            <div class="container-slider">
                                <h3 class="">Stratégie<br>& outils digitaux</h3>
                                <div class="skill-list exclusion">
                                    <span>Stratégie digitale</span>
                                    <span>UX Design</span>
                                    <span>UI Design</span>
                                    <span>Site web corporate,<br>produit,<br>événementiel</span>
                                    <span>Site e-commerce</span>
                                    <span>Référencement</span>
                                    <span>Programmation</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-6" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-6.jpg);">
                            <div class="container-slider">
                                <h3 class="">Social media<br>strategy</h3>
                                <div class="skill-list">
                                    <span>Social media<br>management</span>
                                    <span>Activation sociale</span>
                                    <span>Marketing<br>d'influence<br>(micro et macro)</span>
                                    <span>Création et<br>production de<br>contenu</span>
                                    <span>Community<br>management</span>
                                    <span>Social advertising</span>
                                </div>
                            </div>
                        </div>

                        <div ngxSlickItem class="single-slide metier-7" style="background-image: url(../../../assets/images/l-agence/slider-metier-slide-7.jpg);">
                            <div class="container-slider">
                                <h3 class="">Événementiel</h3>
                                <div class="skill-list">
                                    <span>Production<br>événementielle</span>
                                    <span>Campagnes<br>d'activations</span>
                                    <span>Scénographie</span>
                                    <span>Création stands</span>
                                </div>
                            </div>
                        </div>

                </ngx-slick-carousel>
            </div>
        </div>
    </section>

    <section id="equipe-section" data-anchor="equipe" class="section fp-auto-height-responsive">
        <div class="container">
            <div class="nav-page nav-page-mobile"><span class="current">1</span> / 12</div>
            <div class="top-left">
                <h2>\ Équipe</h2>
                <div class="nav-page"><span class="current">1</span> / 12</div>
            </div>
            <div class="nav-slider">
                <span>Découvrir</span>
                <div class="slideGoLeft" (click)="slickPrevSlide('carousel-team')">
                    <div class="picto">
                        <svg class="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                            <g class="fleche">
                                <path class="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
                                <path class="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
                            </g>
                            <circle cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#fff" stroke-width="2.09"/>
                            <circle class="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                        </svg>                         
                    </div>
                </div>
                <div class="slideGoRight" (click)="slickNextSlide('carousel-team')">
                    <div class="picto">
                        <svg class="picto-cta" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.16 96.16">
                            <g class="fleche">
                                <path class="tete_de_fleche" data-name="tete de fleche" d="M38.33,54.44,50.17,66.28,62,54.44" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
                                <path class="corps_de_fleche" data-name="corps de fleche" d="M50.17,33.51V66.35" transform="translate(-2.1 -1.85)" fill="none" stroke="#fff" stroke-width="6.56"/>
                            </g>
                            <circle cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#fff" stroke-width="2.09"/>
                            <circle class="cercle-noir" cx="48.08" cy="48.08" r="47.03" fill="none" stroke="#9C974F" stroke-width="2.09"/>
                        </svg>                         
                    </div>
                </div>
            </div>
            <div class="equipe-slider">
                <ngx-slick-carousel class="carousel carousel-team" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfigEquipe" 
                        (init)="slickInitEquipe($event)"
                        (afterChange)="afterChangeEquipe($event)"
                        (beforeChange)="beforeChangeEquipe($event)">
                
                        <div *ngFor="let slide of slidesEquipe" ngxSlickItem class="single-slide">
                            <div class="img-container">
                                <img src="../../../assets/images/l-agence/{{slide.img}}" alt="{{slide.name}}">
                            </div>
                            <div class="meta">
                                <h3 class="nom">{{slide.name}}</h3>
                                <p class="poste">{{slide.poste}}</p>
                            </div>
                        </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </section>

    <section id="footer-agence" class="section fp-auto-height">
        <section id="decouvrir" >
            <div class="container">
                <div class="col">
                    <h2>Découvrir aussi</h2>
                </div>
                <div class="col">
                    <app-main-bt [routerLink]="['/le-feed']" class="link-bt">le feed</app-main-bt>
                    <app-main-bt [routerLink]="['/contact']" class="link-bt">contact</app-main-bt>
                    <app-main-bt [routerLink]="['/references']" class="link-bt">références</app-main-bt>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </section>
</div>
</div>