import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { HomepageLoaderComponent } from './page/homepage/homepage-loader/homepage-loader.component';
import { MainBtComponent } from './components/main-bt/main-bt.component';
import { AltBtComponent } from './components/alt-bt/alt-bt.component';
import { FooterComponent } from './components/footer/footer.component';
import { SmoothScroll } from './smoothscroll';
import { AgenceComponent } from './page/agence/agence.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PictoFlecheComponent } from './components/picto-fleche/picto-fleche.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LeFeedComponent } from './page/le-feed/le-feed.component';
import { ReferencesComponent } from './page/references/references.component';
import { SingleReferenceComponent } from './page/references/single-reference/single-reference.component';
import { ContactComponent } from './page/contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';  
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './contact.service';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { Globals } from './global';
import { CandidatureComponent } from './page/contact/candidature/candidature.component';
import { SEOService } from './seo.services';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
};

const routes: Routes = [
  { 
    path: '',
    component: HomepageComponent,
    data: {
      title: 'Pure – Agence de communication 360° à Toulouse et Paris',
      description:'Pure est une agence de communication 360° qui propose à ses clients une approche créative, design et unique de la communication.',
      ogUrl: 'https://www.agence-pure.com/',
    }
  },
  { 
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Pure - Contact',
      description:'Un projet, une question ? Toute l’équipe se tient à disposition pour vous répondre et vous conseiller au mieux.',
      ogUrl: 'https://www.agence-pure.com/contact',
    }
  },
  { 
    path: 'recrutement',
    component: CandidatureComponent,
    data: {
      title: 'Pure – Nous rejoindre',
      description:'Vous souhaitez nous rejoindre ou vous souhaitez déposer une candidature spontanée ? Retrouvez toutes nos offres d’emploi pour rejoindre l’agence.',
      ogUrl: 'https://www.agence-pure.com/recrutement',
    }
  },
  { 
    path: 'references',
    component: ReferencesComponent,
    data: {
      title: 'Pure – Nos clients',
      description:'Découvrez les clients qui nous font confiance pour gérer leur communication.',
      ogUrl: 'https://www.agence-pure.com/references',
    }
  },
  { 
    path: 'references/:cat/:slug',
    component: SingleReferenceComponent,
    data: {
      title: 'Pure – Cas client',
      description:'Découvrez le travail que nous avons réalisé.',
      ogUrl: 'https://www.agence-pure.com/references',
    }
  },
  { 
    path: 'le-feed',
    component: LeFeedComponent,
    data: {
      title: 'Pure – Nos actualités',
      description:'Découvrez toutes nos actualités : la vie d’agence, nos réalisations, évènements…',
      ogUrl: 'https://www.agence-pure.com/le-feed',
    }
  },
  { 
    path: 'l-agence',
    component: AgenceComponent,
    data: {
      title: 'Pure – No standards',
      description:'Chez Pure, nous accompagnons nos clients là où ils n’auraient pas osés. Découvrez notre expertise, ainsi que notre équipe qui est à votre disposition.',
      ogUrl: 'https://www.agence-pure.com/l-agence',
    }
  },
  {path: '404', component: HomepageComponent},
  {path: '**', redirectTo: '/'}
];
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HomepageLoaderComponent,
    MainBtComponent,
    AltBtComponent,
    FooterComponent,
    AgenceComponent,
    PictoFlecheComponent,
    LeFeedComponent,
    ReferencesComponent,
    SingleReferenceComponent,
    ContactComponent,
    HeaderComponent,
    CandidatureComponent
  ],
  imports: [
    AngularFullpageModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    }),
    SlickCarouselModule,
    BrowserAnimationsModule
  ],
  providers: [
    SEOService,
    ContactService,
    SmoothScroll,
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
