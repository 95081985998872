<section id="homepage-loader">
    <div class="container">
        <div class="background-image"></div>
        <div id="cadre" class="cadre">
            <div class="project-name">
                <span class="projet-0">&copy; Vinci Autoroute</span>
                <span class="projet-1">&copy; Pure<br>No Standards</span>
                <span class="projet-2">&copy; Pure<br>No Standards</span>
                <span class="projet-3">&copy; Pure<br>No Standards</span>
                <span class="projet-4">&copy; Atelier2, Rêver, dessiner,<br>imaginer, construire</span>
                <span class="projet-5">&copy; Groupe Mounès,<br>Accélérateur de mobilité</span>
                <span class="projet-6">&copy; Solum<br>Light is coming</span>
                <span class="projet-7">&copy; Pure<br>No Standards</span>
                <span class="projet-8">&copy; Culo,<br>Surcyclé en France</span>
                <span class="projet-9">&copy; Portelli,<br>Créateur d'espaces d vie extérieurs</span>
                <span class="projet-10">&copy; Maison B,<br>Bobine & Bobinette</span>
                <span class="projet-11">&copy; Struktura<br>Home life design</span>
                <span class="projet-12">&copy; ACE,<br>Voyages sur-mesure</span>
                <span class="projet-13">&copy; Mômes,<br>Bande de gourmets</span>
                <span class="projet-14">&copy; Pure<br>No Standards</span>
                <span class="projet-15">&copy; Pure<br>No Standards</span>
            </div>
        </div>
    </div>
</section>